var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('terms-dialog', _vm._b({
    on: {
      "isLoaded": _vm.setTermsList,
      "input": function (value) {
        return _vm.$emit('input', value);
      },
      "isValid": function (isValid) {
        return _vm.$emit('isValid', isValid);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          isAllSelected = _ref.isAllSelected;
        return [_c('div', _vm._g({}, on), [_c('div', {
          staticClass: "pb-12px"
        }, [_c('v-checkbox', _vm._b({
          staticClass: "v-size--large",
          attrs: {
            "readonly": "",
            "label": "약관에 전체 동의합니다.",
            "value": isAllSelected
          }
        }, 'v-checkbox', Object.assign({}, _vm.checkbox_primary), false))], 1), !_vm.hideItems ? _c('v-card', {
          attrs: {
            "tile": "",
            "outlined": ""
          }
        }, [_c('div', {
          staticClass: "pa-16px pa-md-20px"
        }, _vm._l(_vm.termsList, function (terms, index) {
          return _c('v-checkbox', _vm._b({
            key: terms._id,
            class: {
              'mt-8px mt-md-12px': index != 0
            },
            attrs: {
              "value": _vm._selected.includes(terms._id),
              "readonly": ""
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function () {
                return [_vm._v(" " + _vm._s(terms.subject) + " "), _c('span', {
                  staticClass: "pl-4px",
                  class: [terms.necessary ? 'red--text' : 'grey--text text--lighten-1']
                }, [_vm._v("(" + _vm._s(terms.necessary ? "필수" : "선택") + ")")])];
              },
              proxy: true
            }], null, true)
          }, 'v-checkbox', Object.assign({}, _vm.checkbox_primary), false));
        }), 1)]) : _vm._e()], 1)];
      }
    }]),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, 'terms-dialog', {
    params: _vm.params
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }