<template>
    <u-dialog v-model="shows" title="이용약관" persistent width="600">
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, isAllSelected }" />
        </template>
        <div v-if="isLoaded">
            <tit-wrap-x-small>
                <v-checkbox v-model="isAllSelected" v-bind="{ ...checkbox_primary }" readonly label="약관에 전체 동의합니다." class="v-size--large" @click="toggleSelectedAll" />
            </tit-wrap-x-small>
            <v-divider />

            <page-section v-for="term in termsList" :key="term._id" class="page-section--xs pb-0">
                <tit-wrap-x-small>
                    <template #titleContents>
                        {{ term.subject }}<span class="pl-4px" :class="[term.necessary ? 'red--text' : 'grey--text text--lighten-1']">({{ term.necessary ? "필수" : "선택" }})</span>
                    </template>
                    <v-checkbox v-model="selected" v-bind="{ ...checkbox_primary }" :value="{_id: term._id, code: term.code}" label="약관동의" />
                </tit-wrap-x-small>
                <v-card outlined rounded="md">
                    <div class="pa-16px pa-md-20px mh-120px" style="overflow-y: auto"><div v-html="term.content.replace(/\n/gi, '<br/>')" /></div>
                </v-card>
            </page-section>

            <div class="btn-wrap">
                <v-row class="row--sm">
                    <v-col>
                        <v-btn block outlined color="grey" class="v-size--xx-large" @click="cancel">취소</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn block color="grey darken-4" class="v-size--xx-large" :disabled="!isValid" @click="agree"> 동의 </v-btn>
                    </v-col>
                </v-row>
            </div>
        </div>
    </u-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input } from "@/assets/variables";
import { checkbox_primary } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";
import VPreviewInput from "@/components/plugins/vuetify/v-preview-input.vue";

export default {
    components: {
        PageSection,
        UDialog,
        TitWrapXSmall,
        VPreviewInput,
    },
    props: {
        value: { type: Array, default: () => [] },
        params: { type: Object, default: () => ({}) },
    },
    data: () => ({
        checkbox_primary,

        shows: false,
        isLoaded: false,
        selected: [],
        termsList: [],

        attrs_input,
    }),
    computed: {
        isValid() {
            let selected = this.selected.map(({ _id }) => _id );
            return this.termsList.filter(({ necessary }) => necessary).every(({ _id })=> selected.includes(_id));
        },
        isAllSelected() {
            let selected = this.selected.map(({ _id }) => _id );
            return this.termsList.every(({ _id })=> selected.includes(_id));
        },
    },
    created() {
        this.init();
    },
    mounted() {
        this.selected = this.value;
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },

        isLoaded(isLoaded) {
            if (isLoaded) this.$emit("isLoaded", this.termsList);
        },
    },
    methods: {
        async init() {
            try {
                this.isLoaded = false;

                let { params } = this;
                var { termsList } = await api.v1.terms.gets({ params });
                this.termsList = termsList;

                this.isLoaded = true;
            } catch (error) {
                this.$handleError(error);
            }
        },

        toggleSelectedAll() {
            if (this.isAllSelected) this.selected = [];
            else this.selected = this.termsList.map(({ _id, code }) => { return { _id, code } });
            // else this.selected = this.termsList.map({ _id } => _id);
        },
        cancel() {
            this.selected = this.value;
            this.shows = false;
        },
        agree() {
            this.$emit("input", this.selected);
            this.$emit("isValid", this.isValid);
            this.shows = false;
        },
    },
};
</script>

<style></style>
